// eslint-disable-next-line consistent-return
export default defineNuxtRouteMiddleware(async () => {
    const auth = useAuth();

    if (!auth.isAuthenticated()) {
        const url = await auth.login();

        if (url) {
            return navigateTo(url);
        }
    }
});
